import React from "react";
import Header from "../../Component/Header/Header";
import Article from "../../Component/Article/Article";
import Equipe from "../../Component/Equipe/Equipe";
import Footer from "../../Component/Footer/Footer";
import Contact from "../../Component/Contact/ContactBis";
import Event from "../../Component/Event/Event";


function Home() {
  return (
    <main className="overflow-hidden">
      <Header />
      <Event/>
      <Article
        logo="good"
        title="Bienvenue au Club de Football JSWENHECK de Saint Avold !"
        image="FirstPic"
        description="Le Club de Football JSWENHEK de Saint Avold est bien plus qu'une simple équipe sportive, c'est une famille passionnée qui partage l'amour du football et l'esprit d'équipe. Fondé il y a plusieurs années, notre club a su évoluer et se développer, devenant un lieu où les joueurs, les supporters et les passionnés se réunissent pour célébrer leur amour commun pour ce sport."
        />
        <Equipe/>
      <Article
        logo="none"
        title="Rejoinez Nous !"
        image="SecondPic"
        description="Que vous soyez un joueur passionné, un parent enthousiaste ou un fervent supporter, nous vous invitons à rejoindre la grande famille du JSWENHECK. Ensemble, nous construisons un avenir prometteur pour le football à Saint Avold, où l'amitié, le respect et l'amour du jeu sont au cœur de tout ce que nous faisons."
        />
        <Contact />
        <Footer />
    </main>
  );
}

export default Home;
