import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routing from './router';

function App() {
  return (
    <div>
     <BrowserRouter>
      <Routing/>
    </BrowserRouter>
    </div>
  );
}

export default App;