import React from 'react'
import Header from '../../Component/Header/Header'
import Footer from '../../Component/Footer/Footer'
import ObjectMentionsLegales from '../../Component/MentionsLegales/ObjectMentionsLegales'

function MentionsLegales() {
   
  return (

    <main className='position-relative vh-100'>
        <Header />
        <ObjectMentionsLegales/>
        <Footer  position="ok"/>
    </main>
  )
}

export default MentionsLegales