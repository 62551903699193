import React from 'react';
import Logo from "../../images/logoJSW.png";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer(props) {

    const scrollToContact = () => {
        const element = document.getElementById('ContactAncre');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

  
  return (
    <footer
      className="colorDefault position-relative d-flex align-items-center text-white"
    >
      <Link to="/">
        <img src={Logo} alt="Logo du club JS Wenhneck" className="logo" />
      </Link>
      <h3 className="">JSWENHECK</h3>
      <article className="d-flex flex-column align-items-center link">
        <Link to="/mentions-legales" className="text-white mb-2">
          Mentions Légales
        </Link>
        <Link to="/politique-confidentialite" className="text-white">
          Politiques de confidentialité
        </Link>
      </article>
      <ul className="d-flex p-0 justify-content-between align-items-end m-0 mb-2 px-5">
        <li>
          <a
            href="https://www.facebook.com/foot.j.s.wenheck/"
            rel="noreferrer"
            target="_blank"
          >
            <FaFacebookF className="text-white" />
          </a>
        </li>
        <li>
        <Link to="/" onClick={scrollToContact}>

          <p className="m-0 text-white linkAncre">Contact</p>
        </Link>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
