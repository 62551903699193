import React from 'react'
import './event.css'
import logo_switch from "../../images/switch.png";
import horloge from "../../images/horloge.png";

function Event() {
    return (
        <section id='event' className='mb-4'>
            <article className='d-flex justify-content-center flex-column-reverse pt-3'>
                <h2 className='text-center text-white'>OUVERTE</h2>
                <h3 className='text-center text-white'>PORTE</h3>
            </article>
            <article className='colorDefault mx-auto rounded-3 p-1 my-3 px-2'>
                <h3 className='text-center text-white'>Samedi 29 juin</h3>
            </article>
            <article className='row d-flex  justify-content-center'>
                <section className='bg-white d-md-flex col-9 p-3 col-md-6 col-xl-5 rounded-4 row'>
                    <article className='text-center col-md-7 text-md-start border-bottom border-2 border-md-none textColorDefault borderColorDefault'>
                        <h4 className='fw-semibold'>AU PROGRAMME</h4>
                        <p className='px-5 text-center text-md-start p-md-0'>REPAS - PRÉSENTATION DU CLUB ET DES ÉQUIPES - ACTIVITÉS POUR TOUS</p>
                    </article>
                    <article className='d-flex mt-2 col-md-5 align-items-center justify-content-center'>
                        <img id='horloge' src={horloge} className='img-fluid' alt="" />
                        <section className='ms-3'>
                            <p className='my-1 fs-4'>09 : 00</p>
                            <p className='my-1 fs-4'>18 : 00</p>
                        </section>
                    </article>
                </section>
            </article>
            <article className='py-4 d-flex justify-content-center'>
                <a href="https://www.facebook.com/foot.j.s.wenheck/" target="_blank" className='p-2 px-5 textColorDefault fs-3 fw-bold bg-white rounded-3 my-3' rel="noreferrer">EN SAVOIR PLUS</a>
            </article>
            <article className='d-flex justify-content-center py-3 position-relative'>
            <img src={logo_switch} id='logoSwitch' alt="Logo de chezSwitch" />
            </article>
        </section>
    )
}

export default Event