import React from "react";
import Logo from "../../images/logoJSW.png";
import './Article.css'

function Article(props) {
  return (
      <article className={`${props.image} d-flex flex-column justify-content-center align-items-center`}>
        {props.logo === "good" ? (
          <img src={Logo} alt="Logo du club JS Wenhneck" className="mt-5" />
        ) : null}
      <section className="colorDefault text-white d-flex flex-column p-2 rounded-3">
        <h2 className="text-white text-center m-4">{props.title}</h2>
        <p className="textJustify mb-4 mx-4">{props.description}</p>
      </section>
    </article>
  );
}

export default Article;
