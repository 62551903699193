import React from "react";
import "./ObjectMentionsLegales.css";

function ObjectMentionsLegales() {
  return (
    <article className="container">
      <section>
        <h1 className="position-static fw-bold fs-1 text-center my-5">
          MENTIONS LÉGALES
        </h1>
      </section>
      <section className="row m-0 d-flex justify-content-center justify-content-md-around mb-4">
        <ul className="position-static col-10 listing text-center col-md-3  overflow-hidden rounded-3 border border-2 border-black">
          <li className="fw-bold fs-4 border-bottom border-black border-2 colorDefault text-white text-center py-2">
            Responsable du site
          </li>
          <li className="border-bottom border-1 border-black mx-2">JS Wenheck</li>
          <li className="border-bottom border-1 border-black mx-2">SABER AMAMRA</li>
          <li>recrutement@jswenheck.com</li>
        </ul>
        <ul className="position-static col-10 listing text-center col-md-3  overflow-hidden rounded-3 border border-2 border-black">
          <li className="fw-bold fs-4 border-bottom border-2 border-black colorDefault text-white text-center py-2">
            Hebergement
          </li>
          <li className="border-bottom border-1 border-black mx-2">1&1 IONOS SARL</li>
          <li className="border-bottom border-1 border-black mx-2">7 place de la Gare</li>
          <li className="border-bottom border-1 border-black mx-2">57200 Sarreguemines CEDEX</li>
          <li>info@ionos.fr</li>
        </ul>
        <ul className="position-static col-10 listing text-center col-md-3 overflow-hidden rounded-3 border border-2 border-black">
          <li className="fw-bold fs-4 border-bottom border-2 border-black colorDefault text-white text-center py-2">
            Éditeur du site
          </li>
           <li className="border-bottom border-1 border-black mx-2">D13 Studio</li>
           <li className="border-bottom border-1 border-black mx-2">57160 Scy-Chazelles</li>
          <li>info@d13.fr</li>
        </ul>
      </section>
      <section className="row d-flex justify-content-center mb-3">
        <ul className="listing position-static col-10 border overflow-hidden rounded-3 border border-2 border-black mb-5">
          <li className="fw-bold fs-4 border-bottom border-2 border-black colorDefault text-white text-center py-2">
            LIMITATIONS DE RESPONSABILITÉS
          </li>
          <li>
            "Les informations contenues sur ce site sont aussi précises que
            possibles et le site est périodiquement remis à jour."
          </li>
          <li>
            "Tout contenu téléchargé se fait aux risques et périls de
            l'utilisateur et sous sa seule responsabilité"
          </li>
          <li>
            "En conséquence, JS Wenheck ne saurait être tenu responsable d'un
            quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une
            quelconque perte de données consécutives au téléchargement."
          </li>
          <li>
            "Les photos sont contractuelles. Les liens hypertextes mis en place
            dans le cadre du présent site Internet en direction d'autres
            ressources présentes sur le réseau Internet ne sauraient engager la
            responsabilité de JS Wenheck."
          </li>
        </ul>

        <ul className="listing position-static col-10  overflow-hidden rounded-3 border border-2 border-black">
          <li className="fw-bold fs-4 border-bottom border-2 colorDefault border-black text-white text-center py-2">
            LOI INFORMATIQUE & LIBERTÉ
          </li>
          <li>
            "Conformément à la loi Informatique et Libertés en date du 6 janvier
            1978, vous disposez d'un droit d'accès, de rectification, de
            modification et de suppression concernant les données qui vous
            concernent. Vous pouvez exercer ce droit en nous contactant."
          </li>
        </ul>
      </section>
    </article>
  );
}

export default ObjectMentionsLegales;
