import React from "react";
import maillot from "../../images/maillot.jpg";
import "./Equipe.css"

function Equipe() {
  return (
    <article className="container d-md-flex justify-content-center">
      <section className="m-5 d-flex justify-content-center align-items-center containerWidth">
        <img src={maillot} alt="maillot de JS Wenheck" id="maillot" />
      </section>
      <section className="m-5 containerWidth d-flex flex-column justify-content-center"> 

      <h3 className="mb-3 ms-5 fw-bold">
        NOS ÉQUIPES :
      </h3>
      <p className="m-3 mb-0 ms-3 textJustify fw-semibold">
        Le Club de Football JSWENHECK de Saint Avold compte
        plusieurs équipes, des jeunes aux seniors, chacune d'entre elles
        représentant notre passion pour le football et notre détermination à
        exceller. Nos entraîneurs dévoués travaillent sans relâche pour
        développer les compétences de nos joueurs et les aider à atteindre leur
        plein potentiel.
      </p>
      </section>
    </article>
  );
}

export default Equipe;
