import React from "react";
import "./Contact.css";

function Contact() {


  return (
    <div id="ContactAncre" className="container mt-4">
      <h3 className="text-center fw-bold">Contactez-nous</h3>
      <article id="Contact" className="my-5 mt-4 w-100">
        <section className="containeridth p-4">
          <p className="p-3">
            Que vous soyez un joueur débutant rêvant de devenir le prochain champion ou un passionné de football cherchant un endroit où vivre votre amour pour le jeu, le Club de Football JSWENHECK de Saint Avold vous ouvre ses portes. Rejoignez-nous dans cette belle aventure où l'amitié se forge, les compétences se perfectionnent et les souvenirs se créent. Ensemble, faisons de chaque match un moment inoubliable et continuons à écrire l'histoire glorieuse du JSWENHECK.
            <br />
            <span className="mt-4 d-block">
              Au plaisir de vous accueillir parmi nous et de partager ensemble notre passion pour le football !
            </span>
          </p>
          <article className="d-flex flex-column align-items-center fw-bold">
            <a className="my-3 text-dark" href="tel:07 48 81 59 08">07 48 81 59 08</a>
            <a className="my-3 text-dark" href="mailto:recrutement@jswenheck.com">recrutement@jswenheck.com</a>
            <a className="my-3 text-dark" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Stade+de+La+Carri%C3%A8re/@49.101096,6.7211642,17z/data=!3m1!4b1!4m6!3m5!1s0x479455006d18dcfd:0x6e3391bfa9b2a691!8m2!3d49.1010925!4d6.7237391!16s%2Fg%2F11vs7mtt4_?authuser=0&entry=ttu">STADE DE LA CARRIÈRE RUE PONCELET, Saint-Avold, France, 57500</a>
          </article>
        </section>
      </article>
    </div>
  );
}
export default Contact;
