import React from "react";
import "./ObjectPolitiqueConfidentialite.css";

function ObjectPolitiqueConfidentialite() {
  return (
    <article className="container">
      <section>
        <h1 className="position-static text-center fs-1 my-5 fw-bold">
          POLITIQUE DE CONFIDENTIALITÉ
        </h1>
        <p className="fw-normal">
          Cette Politique de confidentialité régit la manière dont les données
          personnelles sont collectées, utilisées et gérées lors de
          l'utilisation de notre site web. En utilisant ce site, vous acceptez
          les pratiques décrites dans cette politique.
        </p>
      </section>
      <section className="mb-5">
        <h2 className="fw-semibold my-4">Collecte des données</h2>
        <p className="fw-normal">
          Nous collectons uniquement les informations suivantes via notre
          formulaire de contact : noms, prénoms, et adresses e-mail. Ces données
          sont exclusivement destinées à l'envoi de communications concernant
          des informations sur notre club de football ou pour répondre aux
          demandes des utilisateurs.
        </p>

        <h2 className="fw-semibold my-4">Utilisation des données</h2>
        <p className="fw-normal">
          Les données sont strictement accessibles au personnel autorisé du club
          de football pour répondre aux demandes des utilisateurs. Aucune
          information n'est stockée sur le site, toutes les données collectées
          sont directement envoyées à la boîte e-mail du club. Des mesures de
          sécurité standard sont en place pour protéger ces données.
        </p>

        <h2 className="fw-semibold my-4">Protection des données</h2>
        <p className="fw-normal">
          Cette Politique de confidentialité régit la manière dont les données
          personnelles sont collectées, utilisées et gérées lors de
          l'utilisation de notre site web. En utilisant ce site, vous acceptez
          les pratiques décrites dans cette politique.
        </p>

        <h2 className="fw-semibold my-4">Droits des utilisateurs</h2>
        <p className="fw-normal">
          Les utilisateurs peuvent demander l'accès à leurs données personnelles
          ou demander leur suppression en nous contactant via les informations
          fournies en bas de cette page.
        </p>

        <h2 className="fw-semibold my-4">Cookies et suivi en ligne</h2>
        <p className="fw-normal">Aucun cookie n'est utilisé sur notre site.</p>

        <h2 className="fw-semibold my-4">
          Modifications de la politique de confidentialité
        </h2>
        <p className="fw-normal">
          Nous nous réservons le droit de mettre à jour cette politique de
          confidentialité. Les changements seront signalés sur cette page.{" "}
        </p>
        <p className="fw-normal">
          Pour toute question ou demande concernant notre Politique de
          confidentialité, veuillez nous contacter à l'adresse suivante :
          <span>recrutement@jswenheck.com</span>
        </p>
      </section>
      
    </article>
  );
}

export default ObjectPolitiqueConfidentialite;
