import { Routes, Route } from "react-router-dom";
import Home from "./Page/Home/Home";
import MentionsLegales from "./Page/MentionsLegales/MentionsLegales";
import PolitiqueConfidentialite from "./Page/PolitiqueConfidentialite/PolitiqueConfidentialite";


export default function Routing() {
    return (
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
        </Routes>
    )
}