import React from 'react'
import Header from '../../Component/Header/Header'
import Footer from '../../Component/Footer/Footer'
import ObjectPolitiqueConfidentialite from '../../Component/ObjectPolitiqueConfidentialite/ObjectPolitiqueConfidentialite'

function PolitiqueConfidentialite() {
  return (
    <main>
        <Header />
        <ObjectPolitiqueConfidentialite/>
        <Footer />
    </main>
  )
}

export default PolitiqueConfidentialite